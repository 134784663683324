/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { Button, Flex, Modal, Text } from '@bami-libs/uikit'

import ModalActions from 'components/ModalActions'
import { TranslateString } from 'utils/translateTextHelpers'
import {
  fetchFarmUserLockedBalanceSeparated,
  fetchFarmUserPendingLockedBalanceSeparated,
} from 'state/farms/fetchFarmUser'
import BigNumber from 'bignumber.js'
import useWeb3 from 'hooks/useWeb3'
import { useClaimLocked } from 'hooks/useHarvest'
import { FarmWithStakedValue } from 'models'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

interface HarvestModalProps {
  onDismiss?: () => void
  farm: FarmWithStakedValue
  account?: string
  old?: boolean
}

const HarvestModal = ({ onDismiss, farm, account, old }: HarvestModalProps) => {
  const [data, setData] = useState<{ oldLocked?: BigNumber; newLocked?: BigNumber }>({})
  const [pendingData, setPendingData] = useState<{ oldPendingLocked?: BigNumber; newPendingLocked?: BigNumber }>({})
  const [pendingTx, setPendingTx] = useState(false)
  const web3 = useWeb3()
  const { onClaimLocked: onClaimLockedOld } = useClaimLocked(farm.lockerAddresses[CHAIN_ID])
  const { onClaimLocked: onClaimLockedNew } = useClaimLocked(farm.newLockerAddresses?.[CHAIN_ID])

  useEffect(() => {
    const getLockedFarmBalance = async () => {
      if (account) {
        const amount = await fetchFarmUserLockedBalanceSeparated(account, farm)
        setData(amount)
      }
    }
    const getLockedPendingFarmBalance = async () => {
      if (account) {
        const amount = await fetchFarmUserPendingLockedBalanceSeparated(account, farm)
        setPendingData(amount)
      }
    }

    getLockedFarmBalance()
    getLockedPendingFarmBalance()
  }, [account, farm])

  const { oldLocked, newLocked } = data
  const { oldPendingLocked, newPendingLocked } = pendingData
  const isOldZero = !oldLocked || parseFloat(web3.utils.fromWei(oldLocked.toString() || '0', 'ether')) === 0
  const isNewZero = !newLocked || parseFloat(web3.utils.fromWei(newLocked.toString() || '0', 'ether')) === 0

  return (
    <Modal title={TranslateString(562, 'Harvest')} onDismiss={onDismiss}>
      <div style={{ padding: 24 }}>
        {!!oldLocked && (
          <Flex justifyContent="space-between">
            <Text color="#4e598c" style={{ fontSize: '.7em' }}>
              {old ? TranslateString(23, 'First Week Locked Reward') : TranslateString(23, 'Available / Locked Reward')}
              :
            </Text>
            <Text color="#4e598c" style={{ fontSize: '.7em' }}>
              {parseFloat(web3.utils.fromWei(oldPendingLocked?.toString() || '0', 'ether')).toLocaleString('en-US', {
                maximumFractionDigits: 4,
              })}{' '}
              /{' '}
              {parseFloat(web3.utils.fromWei(oldLocked?.toString() || '0', 'ether')).toLocaleString('en-US', {
                maximumFractionDigits: 4,
              })}
            </Text>
          </Flex>
        )}
        {!!newLocked && (
          <Flex justifyContent="space-between">
            <Text color="#4e598c" style={{ fontSize: '.7em' }}>
              {TranslateString(23, 'Available / Locked Reward')}:
            </Text>
            <Text color="#4e598c" style={{ fontSize: '.7em' }}>
              {parseFloat(web3.utils.fromWei(newPendingLocked?.toString() || '0', 'ether')).toLocaleString('en-US', {
                maximumFractionDigits: 4,
              })}{' '}
              /{' '}
              {parseFloat(web3.utils.fromWei(newLocked?.toString() || '0', 'ether')).toLocaleString('en-US', {
                maximumFractionDigits: 4,
              })}
            </Text>
          </Flex>
        )}
      </div>
      <ModalActions>
        <Flex flexDirection="column">
          {!!oldLocked && (
            <Button
              style={{ marginBottom: '0.5rem' }}
              disabled={pendingTx || isOldZero}
              onClick={async () => {
                try {
                  setPendingTx(true)
                  await onClaimLockedOld()
                  setPendingTx(false)
                  onDismiss()
                } catch (err) {
                  setPendingTx(false)
                }
              }}
            >
              {pendingTx
                ? TranslateString(488, 'Pending Confirmation')
                : old
                ? TranslateString(10011, 'Claim First Week Reward NOW')
                : TranslateString(10010, 'Claim Unlocked Reward')}
            </Button>
          )}
          {!!newLocked && (
            <Button
              // disabled
              disabled={pendingTx || isNewZero}
              onClick={async () => {
                try {
                  setPendingTx(true)
                  await onClaimLockedNew()
                  setPendingTx(false)
                  onDismiss()
                } catch (err) {
                  setPendingTx(false)
                }
              }}
            >
              {pendingTx
                ? TranslateString(488, 'Pending Confirmation')
                : TranslateString(10010, 'Claim Unlocked Reward')}
            </Button>
          )}
          {!!old && (
            <span style={{ fontSize: '0.75rem', fontStyle: 'italic', marginTop: '1rem' }}>
              {TranslateString(10012, 'You can start to claim your unlocked reward from Mon 24/5/2021 morning!')}
            </span>
          )}
        </Flex>
      </ModalActions>
    </Modal>
  )
}

export default HarvestModal
