import { useWallet } from '@binance-chain/bsc-use-wallet'
import { Address } from 'config/constants/types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fetchFarmUserDataAsync, fetchOldFarmUserDataAsync } from 'state/actions'
import { claimLocked, harvest } from 'utils/callHelpers'
import { useLocker, useMasterchef, useOldMasterchef } from './useContract'

export const useHarvest = (farmPid: number, type = '') => {
  const dispatch = useDispatch()
  const { account } = useWallet()
  const masterChefContract = useMasterchef(type)

  const handleHarvest = useCallback(async () => {
    const txHash = await harvest(masterChefContract, farmPid, account)
    dispatch(fetchFarmUserDataAsync(account))
    return txHash
  }, [account, dispatch, farmPid, masterChefContract])

  return { onReward: handleHarvest }
}

export const useOldHarvest = (farmPid: number) => {
  const dispatch = useDispatch()
  const { account } = useWallet()
  const masterChefContract = useOldMasterchef()

  const handleHarvest = useCallback(async () => {
    const txHash = await harvest(masterChefContract, farmPid, account)
    dispatch(fetchOldFarmUserDataAsync(account))
    return txHash
  }, [account, dispatch, farmPid, masterChefContract])

  return { onReward: handleHarvest }
}

export const useClaimLocked = (address: Address) => {
  const dispatch = useDispatch()
  const { account } = useWallet()
  const lockerContract = useLocker(address)

  const handleClaimLock = useCallback(async () => {
    const txHash = await claimLocked(lockerContract, account)
    dispatch(fetchFarmUserDataAsync(account))
    dispatch(fetchOldFarmUserDataAsync(account))
    return txHash
  }, [account, dispatch, lockerContract])

  if (!address) {
    return { onClaimLocked: null }
  }

  return { onClaimLocked: handleClaimLock }
}
