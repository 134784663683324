import { ResetCSS } from '@bami-libs/uikit'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import bgImg from 'assets/homeBg.png'
import bgImgTop from 'assets/homeBgTop.png'
import BigNumber from 'bignumber.js'
import React, { lazy, Suspense, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { fetchFarmsPublicDataAsync, fetchOldFarmsPublicDataAsync } from 'state/actions'
import OldFarms from 'views/OldFarms'
import { fetchPricesData } from 'state/prices'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import GlobalStyle from './style/Global'
import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Farms = lazy(() => import('./views/Farms'))
const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  const dispatch = useDispatch()
  const intervalFetching = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useEffect(() => {
    dispatch(fetchPricesData())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchOldFarmsPublicDataAsync())
  }, [dispatch])

  useEffect(() => {
    intervalFetching.current = setInterval(() => {
      dispatch(fetchFarmsPublicDataAsync())
      dispatch(fetchOldFarmsPublicDataAsync())
    }, 10000)

    return () => {
      clearInterval(intervalFetching.current)
    }
  }, [dispatch])

  // useFetchPublicData()

  const AppWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    overflow-x: hidden;
    background: ${(props) =>
      props.theme.isDark ? 'linear-gradient(180deg, #141E30 0%, #243B55 100%);' : 'rgba(247, 178, 103. .05)'};

    & > div {
      & > nav {
        background: ${(props) => (props.theme.isDark ? 'linear-gradient(180deg, #141E30 0%, #243B55 100%)' : '#fff')};
        svg {
          fill: #ff8c42;
        }

        & > div:first-child {
          align-items: center;

          button:first-child {
            margin-right: 10px;
          }
          a {
            & > svg {
              width: 156px;
              height: 40px;
              image {
                width: 100%;
                height: auto;
              }

              &:first-child {
                image {
                  width: 250%;
                  height: 100%;
                  x: -46;
                }
              }
            }
          }
        }

        & > div:last-child {
          & > div {
            button {
              background: linear-gradient(180deg, #f7b267 0%, #f79d65 24.7%, #f4845f 51%, #f27059 74.5%, #f25c54 100%);
              color: #fff;
            }
          }
        }
      }
      & > div {
        & > div:first-child {
          background: ${(props) => (props.theme.isDark ? 'linear-gradient(180deg, #141E30 0%, #243B55 100%)' : '#fff')};

          & > div:nth-child(2) {
            background: none;
          }
          * {
            color: #ff8c42;
          }

          svg {
            fill: #ff8c42;
          }

          & > div:first-child {
            & > .ldIlNr,
            & > .ePDKyT {
              background: rgba(255, 140, 66, 0.1);
              box-shadow: inset 4px 0px 0px rgba(255, 140, 66, 1);
            }

            & > div {
              & > a {
                & > svg {
                  margin-right: 14px;
                }
              }

              & > div:first-child {
                & > svg {
                  margin-right: 14px;
                }
              }

              & > div:last-child {
                & > div {
                  background: rgba(255, 140, 66, 0.1);
                }

                & > .jhQKuF,
                & > .bQYCHF {
                  box-shadow: inset 4px 0px 0px rgba(255, 140, 66, 1);
                }
              }
            }
          }
        }

        & > div:nth-child(2) {
          & > div:first-child {
            height: 90vh;
          }
        }

        & > div:last-child {
          background: #ff8c42;
        }

        @media (min-width: 968px) {
          & > .guPHnI {
            width: 50px;
          }
        }
      }
    }

    h2 {
      color: red !important;
    }
  `

  const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 16px;
    min-height: calc(100vh - 152px);
    align-items: center;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;

    background-image: url('/static/media/bg.bfd323f2.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
  `

  const BgImgTop = styled.img`
    position: absolute;
    top: -220px;
    left: -300px;
    // width: 450px;
    opacity: ${(props) => (props.theme.isDark ? '0' : '1')};

    @media (max-width: 1400px) {
      width: 300px;
      top: -130px;
      left: -200px;
    }

    @media (max-width: 570px) {
      width: 150px;
      top: -55px;
      left: -45px;
    }
  `

  const BgImgBottom = styled.img`
    position: fixed;
    bottom: -250px;
    right: -200px;
    // width: 450px;
    opacity: ${(props) => (props.theme.isDark ? '0' : '1')};
    margin: ${() => {
      return '0'
    }};

    @media (max-width: 1400px) {
      width: 300px;
      bottom: -130px;
      right: -120px;
    }

    @media (max-width: 570px) {
      width: 150px;
      bottom: -55px;
      right: -60px;
    }
  `

  return (
    <Router>
      <BgImgBottom src={bgImg} />
      <AppWrapper>
        <ResetCSS />
        <GlobalStyle />
        <Menu>
          <BodyWrapper>
            <BgImgTop src={bgImgTop} />
            <Suspense fallback={<PageLoader />}>
              <Switch>
                <Route path="/" exact>
                  <Farms />
                </Route>
                <Route path="/farms">
                  <Farms />
                </Route>
                <Route path="/farms-old">
                  <OldFarms />
                </Route>
                <Route path="/nests">
                  <Farms tokenMode />
                </Route>
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </BodyWrapper>
        </Menu>
        <NftGlobalNotification />
      </AppWrapper>
    </Router>
  )
}

export default React.memo(App)
