import { Flex, useModal } from '@bami-libs/uikit'
import BigNumber from 'bignumber.js'
import { Address } from 'config/constants/types'
import { useHarvest, useOldHarvest } from 'hooks/useHarvest'
import useI18n from 'hooks/useI18n'
import { FarmWithStakedValue } from 'models'
import React, { useState } from 'react'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import HarvestModal from './HarvestModal'

interface FarmCardActionsProps {
  earnings?: BigNumber
  pid?: number
  lockerAddress?: Address
  farm: FarmWithStakedValue
  account?: string
  old?: boolean
}

const BalanceAndCompound = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const HarvestAction: React.FC<FarmCardActionsProps> = ({ earnings, pid, account, farm, old = false }) => {
  const TranslateString = useI18n()
  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useHarvest(pid, farm.masterChefType)
  const { onReward: onOldReward } = useOldHarvest(pid)
  const [onShowHarvestModal] = useModal(<HarvestModal old={old} account={account} farm={farm} />)
  const hasLocker = farm.newLockerAddresses?.[CHAIN_ID] || farm.lockerAddresses?.[CHAIN_ID]

  const rawEarningsBalance = getBalanceNumber(earnings)
  const displayBalance = rawEarningsBalance.toLocaleString('en-US')

  return (
    <Flex mb="8px" justifyContent="space-between" alignItems="center">
      <h5 className="key" style={{ color: '#4e598c', fontSize: '1.1em', fontWeight: 100 }}>
        {displayBalance}
      </h5>
      <BalanceAndCompound>
        <button
          style={pid === 12 ? { marginTop: '10px' } : {}}
          className="harvest-btn"
          disabled={rawEarningsBalance === 0 || pendingTx}
          type="button"
          onClick={async () => {
            try {
              setPendingTx(true)
              if (old) {
                await onOldReward()
              } else {
                await onReward()
              }
              setPendingTx(false)
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(err)
              setPendingTx(false)
            }
          }}
        >
          {TranslateString(999, 'Harvest')}
        </button>
      </BalanceAndCompound>
      <BalanceAndCompound>
        {!!hasLocker && (
          <button
            style={pid === 12 ? { marginTop: '10px' } : {}}
            className="harvest-btn"
            type="button"
            disabled={!account}
            onClick={() => {
              onShowHarvestModal()
            }}
          >
            {TranslateString(999, 'Claim Lock')}
          </button>
        )}
      </BalanceAndCompound>
    </Flex>
  )
}

export default HarvestAction
