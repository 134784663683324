import { Flex, LinkExternal, Modal, Text } from '@bami-libs/uikit'
import BigNumber from 'bignumber.js'
import { Address } from 'config/constants/types'
import useI18n from 'hooks/useI18n'
import React from 'react'
import styled from 'styled-components'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'

interface ApyCalculatorModalProps {
  onDismiss?: () => void
  lpLabel?: string
  cakePrice?: BigNumber
  apy?: BigNumber
  quoteTokenAdresses?: Address
  quoteTokenSymbol?: string
  tokenAddresses: Address
  isTokenOnly?: boolean
  rewardToken: string
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, auto);
  margin-bottom: 24px;
`

const GridItem = styled.div`
  margin-bottom: '10px';
`

const Description = styled(Text)`
  max-width: 320px;
  margin-bottom: 28px;
`

const IconColor = styled.div`
  a {
    svg {
      fill: #ff8c42 !important;
      path {
        fill: #ff8c42 !important;
      }
    }
  }
`

const ApyCalculatorModal: React.FC<ApyCalculatorModalProps> = ({
  onDismiss,
  lpLabel,
  quoteTokenAdresses,
  quoteTokenSymbol,
  tokenAddresses,
  cakePrice,
  apy,
  isTokenOnly,
  rewardToken,
}) => {
  const TranslateString = useI18n()
  const liquidityUrlPathParts = getLiquidityUrlPathParts({ quoteTokenAdresses, quoteTokenSymbol, tokenAddresses })
  const oneThousandDollarsWorthOfCake = 1000 / cakePrice.toNumber()

  const apyPer1D = apy.times(new BigNumber(100)).div(new BigNumber(365)).toNumber()
  const apyPer7D = apy
    .times(new BigNumber(100))
    .div(new BigNumber(365.0 / 7))
    .toNumber()
  const apyPer30D = apy
    .times(new BigNumber(100))
    .div(new BigNumber(365.0 / 30))
    .toNumber()
  const apyPer365D = apy.times(new BigNumber(100)).toNumber()

  const cakeEarnedPerThousand1D = cakePrice.toNumber() ? (oneThousandDollarsWorthOfCake * apyPer1D) / 100.0 : 0
  const cakeEarnedPerThousand7D = cakePrice.toNumber() ? (oneThousandDollarsWorthOfCake * apyPer7D) / 100.0 : 0
  const cakeEarnedPerThousand30D = cakePrice.toNumber() ? (oneThousandDollarsWorthOfCake * apyPer30D) / 100.0 : 0
  const cakeEarnedPerThousand365D = cakePrice.toNumber() ? (oneThousandDollarsWorthOfCake * apyPer365D) / 100.0 : 0

  return (
    <Modal title="ROI" onDismiss={onDismiss}>
      <Grid>
        <GridItem>
          <Text color="#4e598c" fontSize="12px" bold textTransform="uppercase" mb="20px">
            {TranslateString(999, 'Timeframe')}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="12px" bold color="#4e598c" textTransform="uppercase" mb="20px">
            {TranslateString(999, 'ROI')}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="12px" bold color="#4e598c" textTransform="uppercase" mb="20px">
            {TranslateString(999, `${rewardToken} per $1000`)}
          </Text>
        </GridItem>
        {/* 1 day row */}
        <GridItem>
          <Text color="#4e598c">1d</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{apyPer1D.toFixed(2)}%</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{cakeEarnedPerThousand1D.toFixed(2) || 0}</Text>
        </GridItem>
        {/* 7 day row */}
        <GridItem>
          <Text color="#4e598c">7d</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{apyPer7D.toFixed(2)}%</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{cakeEarnedPerThousand7D.toFixed(2)}</Text>
        </GridItem>
        {/* 30 day row */}
        <GridItem>
          <Text color="#4e598c">30d</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{apyPer30D.toFixed(2)}%</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{cakeEarnedPerThousand30D.toFixed(2)}</Text>
        </GridItem>
        {/* 365 day / APY row */}
        <GridItem>
          <Text color="#4e598c">365d(APY)</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{apyPer365D.toFixed(2)}%</Text>
        </GridItem>
        <GridItem>
          <Text color="#4e598c">{cakeEarnedPerThousand365D.toFixed(2)}</Text>
        </GridItem>
      </Grid>
      <Description fontSize="12px" color="#4e598c">
        {TranslateString(
          999,
          'Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.',
        )}
      </Description>
      <IconColor>
        <Flex justifyContent="center">
          <LinkExternal
            color="#FF8C42"
            href={
              isTokenOnly
                ? `https://app.bami.money/#/trade/${lpLabel}`
                : `https://app.bami.money/#/add/${liquidityUrlPathParts}`
            }
          >
            {TranslateString(999, 'Get')} {lpLabel}
          </LinkExternal>
        </Flex>
      </IconColor>
    </Modal>
  )
}

export default ApyCalculatorModal
