import { MenuEntry } from '@bami-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://app.bami.money/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    href: 'https://app.bami.money/#/swapnew',
  },
  {
    label: 'Liquidity',
    icon: 'TicketIcon',
    href: 'https://app.bami.money/#/pool-new',
  },
  {
    label: 'Bridge',
    icon: 'BridgeIcon',
    href: 'https://app.bami.money/#/bridge',
  },
  {
    label: 'Lending',
    icon: 'BankIcon',
    href: 'https://app.bami.money/#/lending',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Stake',
    icon: 'PoolIcon',
    href: '/nests',
  },
  {
    label: 'Time Locked',
    icon: 'FarmIcon',
    href: '/farms-old',
  },
  {
    label: '',
    icon: 'PoolIcon',
    href: '/',
    calloutClass: 'empty-menu-link',
  },
  {
    label: 'Docs',
    icon: 'MoreIcon',
    href: 'https://bami.gitbook.io/bami/',
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/bamidefi?lang=en',
  },
  {
    label: 'Youtube',
    icon: 'YoutubeIcon',
    href: 'https://www.youtube.com/channel/UCPx70SlwQrQWCcD4sUghr5A',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/bamimoney',
  },
  {
    label: 'Blog',
    icon: 'GroupsIcon',
    href: 'https://blog.bami.money/',
  },
  {
    label: 'Audit by CertiK',
    icon: 'ProtectIcon',
    href: 'https://www.certik.org/projects/bami',
  },
]

export default config
