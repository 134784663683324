import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'BAMI-USDT LP',
    lpAddresses: {
      56: '0xb33602ac4bf84b144894cce8f6c39f6c2136340f',
    },
    tokenSymbol: 'BAMI',
    tokenAddresses: contracts.bami,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    rewardTokenSymbol: 'BAMI',
    lockerAddresses: {
      97: '',
      56: '0x81a1387f270121e6147ff43dbe90937c81a827c4',
    },
  },
  {
    pid: 1,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BAMI',
    lpAddresses: {
      97: '',
      56: '0x8249BC1dEA00660d2d38dF126b53C6c9A733e942',
    },
    tokenSymbol: 'BAMI',
    tokenAddresses: contracts.bami,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    rewardTokenSymbol: 'BAMI',
    lockerAddresses: {
      97: '',
      56: '0x81a1387f270121e6147ff43dbe90937c81a827c4',
    },
  },
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'LTD-BUSD LP',
    lpAddresses: {
      56: '0x31866d9c9097d7c5b6336889b8dd80875c6ca39d',
    },
    tokenSymbol: 'LTD',
    tokenAddresses: contracts.ltd,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    rewardTokenSymbol: 'LTD',
    lockerAddresses: {
      97: '',
      56: '',
    },
    masterChefType: 'ltd',
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'LTD-BAMI LP',
    lpAddresses: {
      56: '0xE4DF1e1201bcca4A05f304e7Df161073A008F231',
    },
    tokenSymbol: 'LTD',
    tokenAddresses: contracts.ltd,
    quoteTokenSymbol: QuoteToken.BAMI,
    quoteTokenAdresses: contracts.bami,
    rewardTokenSymbol: 'LTD',
    lockerAddresses: {
      97: '',
      56: '',
    },
    masterChefType: 'ltd',
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'LTD-VIDB LP',
    lpAddresses: {
      56: '0x10d66cdF19dF32f14Be4afdfd4004B83F79fD64d',
    },
    tokenSymbol: 'LTD',
    tokenAddresses: contracts.ltd,
    quoteTokenSymbol: QuoteToken.VIDB,
    quoteTokenAdresses: contracts.vidb,
    rewardTokenSymbol: 'LTD',
    lockerAddresses: {
      97: '',
      56: '',
    },
    masterChefType: 'ltd',
  },
]

export default farms
