export default {
  bami: {
    56: '0x8249BC1dEA00660d2d38dF126b53C6c9A733e942',
    97: '',
  },
  ltd: {
    56: '0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4',
    97: '',
  },
  lpLtdBusd: {
    56: '0x31866D9c9097d7C5b6336889b8dD80875C6Ca39D',
    97: '',
  },
  cake: {
    56: '0x8249BC1dEA00660d2d38dF126b53C6c9A733e942',
    97: '',
  },
  masterChef: {
    56: '0xda4ae8d1fffd95adbd6b161098556b88919462f0',
    97: '0x8249BC1dEA00660d2d38dF126b53C6c9A733e942',
  },
  ltdMasterChef: {
    56: '0x07211ff5aF262Fc0fA3D54432A058917a4B1e271',
    97: '0xd71Dd396290E7212DE42026E63281e5c69661198',
  },
  oldMasterChef: {
    56: '0x40899754FEb55e0898577b0ace055d3cD1dADe22',
    97: '0x8249BC1dEA00660d2d38dF126b53C6c9A733e942',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
  },
  vidb: {
    56: '0x90dc17d47d739ee84d61bd0ec828a24881e2a0b4',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  },
  btc: {
    56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  },
  vndc: {
    56: '0xdde5b33a56f3f1c22e5a6bd8429e6ad508bff24e',
  },
}
