import { Skeleton } from '@bami-libs/uikit'
import BigNumber from 'bignumber.js'
import GradientButton from 'components/Button/button'
import useWeb3 from 'hooks/useWeb3'
import React, { useEffect, useMemo, useState } from 'react'
import { fetchFarmUserLockedBalance } from 'state/farms/fetchFarmUser'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import ApyButton from 'views/Farms/components/FarmCard/ApyButton'
import { useFarmUserStakedValue } from 'state/hooks'
import { formatNumber } from 'utils/format'
import DetailsSection from 'views/Farms/components/FarmCard/DetailsSection'
import { provider } from 'web3-core'
import { FarmWithStakedValue } from 'models'

import angleDown from '../../assets/angleDown.png'
import check from '../../assets/check.png'
import CardActionsContainer from './CardActionsContainer'

const Card = styled.div`
  padding: 14px 10px;
  background: ${(props: any) => (props.theme.isDark ? '#141E30' : '#fff')};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 10px;
  display: flex;
    flex-direction: column;
    align-items: center;

  div.card_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    & > img {
      flex: 0 0 27%;
      max-width: 27%;
    }

    div.header-details {
      flex: 0 0 60%;
      max-width: 60%;

      h5.text {
        font-size: 1.1em;
        font-weight: 500;
        margin-bottom: 5px;
        color: #4e598c;

        @media(max-width: 530px){
            font-size: 1.4em;
        }

        @media(max-width: 450px){
            font-size: 1.6em;
        }

        @media(max-width: 380px){
            font-size: 1.3em;
        }
      }

      & > div {
        display: flex;
        align-items: center;

        button {
          margin-right: 0;
          margin-left: auto;

          @media(max-width: 530px){
              font-size: .9em;
          }

          @media(max-width: 450px){
            font-size: 1.1em;
            border-radius: 10px;
        }

        @media(max-width: 380px){
            font-size: 1.1em;
        }
        }

        & > div {
          display: flex;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #4e598c;
          padding: 3px 5px;

          img{
              width: 30%;
          }

          h5 {
            font-size: 0.7em;
            font-weight: 200;
            color: #ff8c42;
            margin-left: 5px;

            @media(max-width: 530px){
                font-size: .9em;
                white-space: nowrap;
            }

            @media(max-width: 450px){
                font-size: 1.2em;
            }

            @media(max-width: 380px){
                font-size: 1em;
            }
          }

          @media(max-width: 530px){
              padding: 8px 14px;
            border-radius: 10px;
          }
        }
      }
    }
  }

  div.card-body {

    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .values_container {
        width: 100%;

        .harvest-btn{
          border: none;
          outline: none;
          padding: 2px 10px;
          border-radius: 20px;
          background: linear-gradient(180deg,#F7B267 0%,#F79D65 24.7%,#F4845F 51%,#F27059 74.5%,#F25C54 100%);
          cursor: pointer;
          font-size: 1em;

          &:disabled {
            background: rgba(112, 112, 112, 0.3);
            cursor: not-allowed;
          }

          @media(max-width: 530px){
            font-size: 1.2em;
        }

        @media(max-width: 450px){
            font-size: 1.4em;
            margin-bottom: 14px;
        }

        @media(max-width: 380px){
            font-size: 1.2em;
        }
      }
      .key-and-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .key,
        .value {
          font-size: 0.7em;
          font-weight: 100;
          display:flex;
          align-items: center;

          @media(max-width: 530px){
            font-size: .9em;
            white-space: nowrap;
        }

        @media(max-width: 450px){
            font-size: 1.2em;
            margin-bottom: 14px;
        }

        @media(max-width: 380px){
            font-size: 1.1em;
        }
        }

        .key {
          color: #4e598c;
        }

        .value {
          color: #ff8c42;
        }
      }

      .deposit-fee {
  
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
  
          .key,
          .value {
            font-size: 1.1em;
            font-weight: 100;

            @media(max-width: 530px){
                font-size: 1.4em;
                white-space: nowrap;
            }

            @media(max-width: 450px){
                font-size: 1.6em;
                margin-bottom: 14px;
            }

            @media(max-width: 380px){
                font-size: 1.3em;
            }
          }
  
          .key {
            color: #4e598c;
          }
  
          .value {
            color: #ff8c42;
          }
  
          button{
              border: none;
              outline: none;
              padding: 2px 10px;
              border-radius: 20px;
              background: rgba(112, 112, 112, 0.3);
              color: rgba(112, 112, 112, 0.5);
              font-size: 1em;

              @media(max-width: 530px){
                font-size: 1.2em;
            }

            @media(max-width: 450px){
                font-size: 1.4em;
                margin-bottom: 14px;
            }

            @media(max-width: 380px){
                font-size: 1.2em;
            }
          }
      }
    }

    &>button{
        margin-right: 0;
        margin: 0 auto;

        @media(max-width: 530px){
            font-size: 1.2em;
        }

        @media(max-width: 450px){
            font-size: 1.4em;
        }

        @media(max-width: 380px){
            font-size: 1.2em;
        }
    }

    button.details-btn{
        border: none;
        outline: none;
        background: none;
        font-size: .6em;
        margin-top: 10px;
        color: rgba(78, 89, 140,.6);
        cursor: pointer;

        img{
            width: 7px;
        }

        @media(max-width: 530px){
            font-size: .8em;
        }

        @media(max-width: 450px){
            font-size: 1em;
        }

        @media(max-width: 380px){
            font-size: .8em;
        }
    }
    }
}

`

const ExpandingWrapper = styled.div<{ expanded: boolean }>`
  height: ${(props) => (props.expanded ? '100%' : '0px')};
  width: 100%;
  overflow: hidden;
`

interface FarmCardProps {
  farm: FarmWithStakedValue
  removed: boolean
  cakePrice?: BigNumber
  bnbPrice?: BigNumber
  ethereum?: provider
  account?: string
  old?: boolean
}

const StackCard: React.FC<FarmCardProps> = ({ farm, removed, cakePrice, ethereum, account, old = false }) => {
  const [showExpandableSection, setShowExpandableSection] = useState(false)
  const [lockedAmount, setLockedAmount] = useState(0)

  useEffect(() => {
    const getLockedFarmBalance = async () => {
      if (account) {
        const amount = await fetchFarmUserLockedBalance(account, farm)
        setLockedAmount(amount)
      }
    }

    getLockedFarmBalance()
  }, [account, farm])

  // const isCommunityFarm = communityFarms.includes(farm.tokenSymbol)
  // We assume the token name is coin pair + lp e.g. CAKE-BNB LP, LINK-BNB LP,
  // NAR-CAKE LP. The images should be cake-bnb.svg, link-bnb.svg, nar-cake.svg
  // const farmImage = farm.lpSymbol.split(' ')[0].toLocaleLowerCase()
  const farmImage = farm.isTokenOnly
    ? `${farm.tokenSymbol.toLowerCase()}.svg`
    : `${farm.tokenSymbol.toLowerCase()}-${farm.quoteTokenSymbol.toLowerCase()}.svg`
  const prices = useSelector((state) => state.prices.data)

  const totalValue: BigNumber = useMemo(() => {
    if (!farm.lpTotalInQuoteToken) {
      return null
    }
    const priceToken = farm.isTokenOnly ? farm.lpSymbol : farm.quoteTokenSymbol

    return new BigNumber(prices[priceToken]).times(farm.lpTotalInQuoteToken)
  }, [farm.isTokenOnly, farm.lpSymbol, farm.lpTotalInQuoteToken, farm.quoteTokenSymbol, prices])
  const totalValueFormated = !Number.isNaN(Number(totalValue))
    ? `$${Number(totalValue).toLocaleString('en-US', { maximumFractionDigits: 0 })}`
    : '-'
  const web3 = useWeb3()
  const lockedAmountFormated = useMemo(
    () =>
      lockedAmount &&
      parseFloat(web3.utils.fromWei(lockedAmount.toString() || '0', 'ether')).toLocaleString('en-US', {
        maximumFractionDigits: 4,
      }),
    [lockedAmount, web3.utils],
  )

  const lpLabel = farm.lpSymbol
  let farmAPY =
    farm.apy &&
    farm.apy.times(new BigNumber(100)).toNumber().toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  if (farmAPY === 'NaN') {
    farmAPY = '-'
  } else {
    farmAPY += '%'
  }
  const userLiquidityValue = useFarmUserStakedValue(farm.pid, farm.masterChefType)
  const userLiquidityPercent =
    !Number.isNaN(Number(totalValue)) && Number(totalValue) !== 0
      ? `${formatNumber(userLiquidityValue.div(totalValue).times(100).toNumber(), 4)}%`
      : '0%'

  const { quoteTokenAdresses, quoteTokenSymbol, tokenAddresses } = farm
  const depositFee = farm.depositFeeBP ? `${farm.depositFeeBP / 100}%` : '-'

  return (
    <Card>
      <div className="card_header">
        <img src={`/images/farms/${farmImage}`} alt={farm.tokenSymbol} />
        <div className="header-details">
          <h5 className="text">{lpLabel}</h5>
          <div>
            {farm.depositFeeBP === 0 ? (
              <div>
                <img src={check} alt="" />
                <h5 className="no-fees">No fees</h5>
              </div>
            ) : null}
            <GradientButton name={farm.multiplier} fontSize=".8em" padding="5px 10px" />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="values_container">
          <div className="key-and-value">
            <h5 className="key">APR</h5>
            <h5 className="value">
              {farm.apy ? (
                <>
                  <ApyButton
                    rewardToken={farm.rewardTokenSymbol}
                    isTokenOnly={farm.isTokenOnly}
                    lpLabel={lpLabel}
                    quoteTokenAdresses={quoteTokenAdresses}
                    quoteTokenSymbol={quoteTokenSymbol}
                    tokenAddresses={tokenAddresses}
                    cakePrice={cakePrice}
                    apy={farm.apy}
                  />
                  {farmAPY}
                </>
              ) : (
                <Skeleton height={24} width={80} />
              )}
            </h5>
          </div>
          <div className="key-and-value" style={{ marginBottom: '20px' }}>
            <h5 className="key">Earn</h5>
            <h5 className="value">{farm.rewardTokenSymbol}</h5>
          </div>
          <div className="deposit-fee">
            <h5 className="key">Deposit Fee:</h5>
            <h5 className="value">{depositFee}</h5>
          </div>
          <CardActionsContainer old={old} farm={farm} ethereum={ethereum} account={account} />
        </div>
        <button type="button" className="details-btn" onClick={() => setShowExpandableSection(!showExpandableSection)}>
          Details <img src={angleDown} alt="" />
        </button>
        <ExpandingWrapper expanded={showExpandableSection}>
          <DetailsSection
            old={old}
            account={account}
            removed={removed}
            isTokenOnly={farm.isTokenOnly}
            bscScanAddress={
              farm.isTokenOnly
                ? `https://bscscan.com/token/${farm.tokenAddresses[process.env.REACT_APP_CHAIN_ID]}`
                : `https://bscscan.com/token/${farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]}`
            }
            totalValueFormated={totalValueFormated}
            lpLabel={lpLabel}
            quoteTokenAdresses={quoteTokenAdresses}
            quoteTokenSymbol={quoteTokenSymbol}
            tokenAddresses={tokenAddresses}
            lockedAmount={lockedAmountFormated}
            userLiquidity={userLiquidityPercent}
            rewardToken={farm.rewardTokenSymbol}
          />
        </ExpandingWrapper>
      </div>
    </Card>
  )
}

export default StackCard
