import axios from 'axios'
import contracts from 'config/constants/contracts'
import { getPriceByLP } from 'utils/callHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const formatPriceResponse = (raw: any) => {
  const result: any = {}
  Object.values(raw).forEach((o: any) => {
    result[o.symbol.toUpperCase()] = o.price
  })

  // if (!result.VNDC || result.VNDC === '0') {
  //   result.VNDC = 0.00005
  // }

  // if (!result.BAMI || result.BAMI === '0') {
  //   result.BAMI = 0.075
  // }

  result.BNB = result.WBNB
  result.BTC = result.BTCB
  result.VIDB = 1.1

  return result
}

export const fetchPrices = async () => {
  const data = await Promise.allSettled([
    axios.get('https://api.pancakeswap.info/api/v2/tokens'),
    axios.get(
      'https://exchange.vndc.io/exchange/api/v1/ticker/24hr/vidb?fbclid=IwAR2MOVUemjnG9te8fUuVahb0ZPs8dZ08AJdbAHfGRxVeXP-tW-Tf8vW_2VE',
    ),
    axios.get('https://api.pancakeswap.info/api/v2/summary'),
    axios.get('https://api.dex.guru/v1/tokens/0x8249bc1dea00660d2d38df126b53c6c9a733e942?network=bsc'),
  ])
  let pricesByAddress = {}
  const prices = data.reduce((res, cur) => {
    if (cur?.status === 'fulfilled') {
      if (cur.value.config.url?.includes('https://exchange.vndc.io/exchange/api/v1/ticker/24hr/vidb')) {
        return {
          ...res,
          VIDB: cur.value.data.data.lastPrice / 23000.0,
        }
      }
      if (cur.value.config.url?.includes('https://api.pancakeswap.info/api/v2/summary')) {
        const VNDCpair = '0x55d398326f99059fF775485246999027B3197955_0xDDE5B33a56f3F1C22e5a6bd8429E6ad508BFF24E'

        return {
          ...res,
          VNDC: 1 / cur.value.data.data[VNDCpair]?.price,
        }
      }
      if (
        cur.value.config.url?.includes(
          'https://api.dex.guru/v1/tokens/0x8249bc1dea00660d2d38df126b53c6c9a733e942?network=bsc',
        )
      ) {
        return {
          ...res,
          BAMI: cur.value.data?.priceUSD,
        }
      }
      pricesByAddress = cur.value.data.data
      return {
        ...res,
        ...formatPriceResponse(cur.value.data.data),
      }
    }
    return res
  }, {})

  const ltdPrice = await getPriceByLP(contracts.lpLtdBusd[CHAIN_ID], contracts.ltd[CHAIN_ID], pricesByAddress)
  // @ts-ignore
  prices.LTD = ltdPrice

  return prices
}

export default fetchPrices
