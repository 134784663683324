import { useWallet } from '@binance-chain/bsc-use-wallet'
import BigNumber from 'bignumber.js'
import StackCard from 'components/StackCard/stack-card'
import { BLOCKS_PER_YEAR } from 'config'
import { QuoteToken } from 'config/constants/types'
import useI18n from 'hooks/useI18n'
import useRefresh from 'hooks/useRefresh'
import { FarmWithStakedValue } from 'models'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'
import { fetchOldFarmUserDataAsync } from 'state/actions'
import { useOldFarms, usePriceBnbBusd } from 'state/hooks'
import styled from 'styled-components'
import { provider } from 'web3-core'

const FarmsPage = styled.div`
  width: 1000px;
  margin: 0 auto;

  & > h5 {
    font-size: 1.4em;
    font-weight: 500;
    text-align: center;

    @media (max-width: 450px) {
      text-align: left;
      margin-left: 20px;
    }

    @media (max-width: 360px) {
      margin-left: 0px;
    }
  }

  & > h5.header {
    color: #4e598c;
    margin-bottom: 20px;

    @media (max-width: 450px) {
      margin-bottom: 10px;
    }
  }

  & > h5.headline {
    color: #ff8c42;
    margin-bottom: 50px;

    @media (max-width: 450px) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 1260px) {
    width: 100%;
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`

const CardContainer = styled.div`
  flex: 0 0 33%;
  max-width: 33%;
  margin-bottom: 40px;
  padding: 0 10px;

  @media (max-width: 1260px) {
    flex: 0 0 30%;
    max-width: 30%;
  }

  @media (max-width: 800px) {
    flex: 0 0 40%;
    max-width: 40%;
  }

  @media (max-width: 630px) {
    flex: 0 0 45%;
    max-width: 45%;
  }

  @media (max-width: 530px) {
    flex: 0 0 60%;
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  @media (max-width: 450px) {
    flex: 0 0 90%;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  @media (max-width: 360px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

export interface FarmsProps {
  tokenMode?: boolean
}

const OldFarms: React.FC<FarmsProps> = () => {
  const { path } = useRouteMatch()
  const TranslateString = useI18n()
  const farmsLP = useOldFarms()
  const bnbPrice = usePriceBnbBusd()
  const { account, ethereum }: { account: string; ethereum: provider } = useWallet()
  const prices = useSelector((state) => state.prices.data)

  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchOldFarmUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const activeFarms = farmsLP
  // /!\ This function will be removed soon
  // This function compute the APY for each farm and will be replaced when we have a reliable API
  // to retrieve assets prices against USD
  const farmsList = useCallback(
    (farmsToDisplay, removed: boolean) => {
      const farmsToDisplayWithAPY: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
        const cakePrice = new BigNumber(prices[farm.rewardTokenSymbol] || 0)
        const cakeRewardPerBlock = new BigNumber(farm.bamiPerBlock || 1)
          .times(new BigNumber(farm.poolWeight))
          .div(new BigNumber(10).pow(18))
        const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR)

        let apy = cakePrice.times(cakeRewardPerYear)

        let totalValue = new BigNumber(farm.lpTotalInQuoteToken || 0)

        if (farm.quoteTokenSymbol === QuoteToken.BNB) {
          totalValue = totalValue.times(bnbPrice)
        }

        if (totalValue.comparedTo(0) > 0) {
          apy = apy.div(totalValue)
        }

        return { ...farm, apy }
      })
      return farmsToDisplayWithAPY.map((farm) => (
        <CardContainer key={farm.pid}>
          <StackCard
            key={farm.pid}
            farm={farm}
            removed={removed}
            bnbPrice={bnbPrice}
            cakePrice={new BigNumber(prices[farm.rewardTokenSymbol] || 0)}
            ethereum={ethereum}
            account={account}
            old
          />
        </CardContainer>
      ))
    },
    [prices, bnbPrice, ethereum, account],
  )

  return (
    <FarmsPage>
      <h5 className="headline">
        {TranslateString(
          10006,
          'These farm pools is deprecated, you will not receive any reward for staking your LP here, please remove all staking from these pools, then remove them from your liquidity pool. Then add your funds to our new liquidity pools, then stake your lp in our new farms.',
        )}
      </h5>

      <div style={{ display: 'flex' }}>
        <Route exact path={`${path}`}>
          <CardsContainer>{farmsList(activeFarms, false)}</CardsContainer>
        </Route>
      </div>
    </FarmsPage>
  )
}

export default OldFarms
