import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    border: none;
    outline: none;
    background linear-gradient(180deg, #F7B267 0%, #F79D65 24.7%, #F4845F 51%, #F27059 74.5%, #F25C54 100%);
    color: #fff;
    border-radius: ${(props: any) => (props.borderRadius ? props.borderRadius : '5px')};
    padding: ${(props: any) => (props.padding ? props.padding : '10px 14px')};
    font-size: ${(props: any) => (props.fontSize ? props.fontSize : '.8em')};
    width: ${(props: any) => (props.width ? props.width : 'auto')};
    font-weight: 400;
    margin: ${(props: any) => (props.margin ? props.margin : '0')};
    margin-right: ${(props: any) => (props.marginRight ? props.marginRight : '24px')};
    cursor: pointer;

    &:disabled {
      background: rgba(112, 112, 112, 0.3);
      cursor: not-allowed;
    }
`

function GradientButton(props) {
  const { name } = props
  return <Button {...props}>{name}</Button>
}

export default GradientButton
