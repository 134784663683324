import { Flex, Link, LinkExternal, Text } from '@bami-libs/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { Address } from 'config/constants/types'
import useI18n from 'hooks/useI18n'
import React from 'react'
import styled from 'styled-components'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'

export interface ExpandableSectionProps {
  isTokenOnly?: boolean
  bscScanAddress?: string
  removed?: boolean
  totalValueFormated?: string
  lpLabel?: string
  quoteTokenAdresses?: Address
  quoteTokenSymbol?: string
  tokenAddresses: Address
  lockedAmount?: string
  account?: string
  userLiquidity?: string
  old?: boolean
  rewardToken?: string
}

const Wrapper = styled.div`
  margin-top: 24px;
`

const StyledLinkExternal = styled(LinkExternal)`
  text-decoration: none;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;

  svg {
    padding-left: 4px;
    height: 18px;
    width: auto;
    fill: #ff8c42;
  }
`

const DetailsSection: React.FC<ExpandableSectionProps> = ({
  isTokenOnly,
  bscScanAddress,
  removed,
  totalValueFormated,
  lpLabel,
  quoteTokenAdresses,
  quoteTokenSymbol,
  tokenAddresses,
  lockedAmount,
  userLiquidity,
  account,
  old = false,
  rewardToken = 'BAMI',
}) => {
  const TranslateString = useI18n()
  const liquidityUrlPathParts = getLiquidityUrlPathParts({ quoteTokenAdresses, quoteTokenSymbol, tokenAddresses })

  return (
    <Wrapper>
      <Flex justifyContent="space-between" style={{ width: '100%' }}>
        <Text color="#4e598c" style={{ fontSize: '.7em' }}>
          {TranslateString(316, 'Deposit')}:
        </Text>
        <StyledLinkExternal
          style={{ color: '#4e598c', fontSize: '.7em' }}
          href={
            isTokenOnly
              ? `https://app.bami.money/#/trade/${lpLabel}`
              : `https://app.bami.money/#/add/${liquidityUrlPathParts}`
          }
        >
          {lpLabel}
        </StyledLinkExternal>
      </Flex>
      {!removed && (
        <Flex justifyContent="space-between">
          <Text color="#4e598c" style={{ fontSize: '.7em' }}>
            {TranslateString(23, 'Total Liquidity')}:
          </Text>
          <Text color="#4e598c" style={{ fontSize: '.7em' }}>
            {totalValueFormated}
          </Text>
        </Flex>
      )}
      {!!account && (
        <Flex justifyContent="space-between">
          <Text color="#4e598c" style={{ fontSize: '.7em' }}>
            {TranslateString(23, 'Your Liquidity')}:
          </Text>
          <Text color="#4e598c" style={{ fontSize: '.7em' }}>
            {userLiquidity}
          </Text>
        </Flex>
      )}
      {!!lockedAmount && (
        <Flex justifyContent="space-between">
          <Text color="#4e598c" style={{ fontSize: '.7em' }}>
            {TranslateString(1006, `Your locked ${rewardToken}`)}:
            <QuestionHelper text={old ? TranslateString(10009, '') : TranslateString(10020, '')} />
          </Text>
          <Text color="#4e598c" style={{ fontSize: '.7em' }}>
            {lockedAmount}
          </Text>
        </Flex>
      )}
      <Flex justifyContent="flex-start">
        <Link external href={bscScanAddress} style={{ color: '#FF8C42', fontSize: '.7em' }} bold={false}>
          {TranslateString(356, 'View on BscScan')}
        </Link>
      </Flex>
    </Wrapper>
  )
}

export default DetailsSection
