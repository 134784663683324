/* eslint-disable import/prefer-default-export */
export const formatNumber = (raw: number | string, fraction = 2) => {
  let input
  if (typeof raw === 'string') {
    try {
      input = parseFloat(raw) || 0
    } catch (e) {
      input = 0
    }
  } else {
    input = raw
  }
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: fraction }).format(input)
}
