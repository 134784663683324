import { Flex, Text } from '@bami-libs/uikit'
import BigNumber from 'bignumber.js'
import GradientButton from 'components/Button/button'
import UnlockButton from 'components/UnlockButton'
import { useApprove } from 'hooks/useApprove'
import useI18n from 'hooks/useI18n'
import React, { useCallback, useMemo } from 'react'
import { useFarmFromPid, useFarmUser } from 'state/hooks'
import { Farm } from 'state/types'
import styled from 'styled-components'
import { getContract } from 'utils/erc20'
import { provider } from 'web3-core'
import HarvestAction from './HarvestAction'
import StakeAction from './StakeAction'

const Action = styled.div`
  padding-top: 16px;
`
export interface FarmWithStakedValue extends Farm {
  apy?: BigNumber
}

interface FarmCardActionsProps {
  farm: FarmWithStakedValue
  ethereum?: provider
  account?: string
  old?: boolean
}

const CardActions: React.FC<FarmCardActionsProps> = ({ farm, ethereum, account, old = false }) => {
  const TranslateString = useI18n()
  const { pid, lpAddresses, tokenAddresses, isTokenOnly, depositFeeBP } = useFarmFromPid(farm.pid, farm.masterChefType)
  const { allowance, tokenBalance, stakedBalance, earnings } = useFarmUser(pid, farm.masterChefType)
  const lpAddress = lpAddresses[process.env.REACT_APP_CHAIN_ID]
  const tokenAddress = tokenAddresses[process.env.REACT_APP_CHAIN_ID]
  const lpName = farm.lpSymbol.toUpperCase()
  const isApproved = account && allowance && allowance.isGreaterThan(0)

  const lpContract = useMemo(() => {
    if (isTokenOnly) {
      return getContract(ethereum as provider, tokenAddress)
    }
    return getContract(ethereum as provider, lpAddress)
  }, [ethereum, lpAddress, tokenAddress, isTokenOnly])

  const { onApprove } = useApprove(lpContract, farm.masterChefType)

  const handleApprove = useCallback(async () => {
    try {
      await onApprove()
    } catch (e) {
      console.error(e)
    }
  }, [onApprove])

  const renderApprovalOrStakeButton = () => {
    return isApproved ? (
      <StakeAction
        old={old}
        stakedBalance={stakedBalance}
        tokenBalance={tokenBalance}
        tokenName={lpName}
        pid={pid}
        depositFeeBP={depositFeeBP}
        farm={farm}
      />
    ) : (
      <GradientButton
        disabled={old}
        width="100%"
        marginRight="0"
        onClick={handleApprove}
        name={TranslateString(999, 'Approve Contract')}
      />
    )
  }

  return (
    <Action>
      <Flex>
        <Text bold textTransform="uppercase" color="#4e598c" fontSize="12px" pr="3px">
          {farm.rewardTokenSymbol}
        </Text>
        <Text bold textTransform="uppercase" color="#4e598c" fontSize="12px">
          {TranslateString(999, 'Earned')}
        </Text>
      </Flex>
      <HarvestAction old={old} farm={farm} account={account} earnings={earnings} pid={pid} />
      <Flex mb="20px">
        <Text bold textTransform="uppercase" color="#4e598c" fontSize="12px" pr="3px">
          {lpName}
        </Text>
        <Text bold textTransform="uppercase" color="#4e598c" fontSize="12px">
          {TranslateString(999, 'Staked')}
        </Text>
      </Flex>
      {!account ? <UnlockButton mt="8px" fullWidth /> : renderApprovalOrStakeButton()}
    </Action>
  )
}

export default CardActions
