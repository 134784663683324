/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchPrices } from './fetchPrices'
import { PricesState } from '../types'

const initialState: PricesState = { data: {} }

export const pricesSlice = createSlice({
  name: 'Prices',
  initialState,
  reducers: {
    setPrices: (state, action) => {
      state.data = {
        ...action.payload,
      }
    },
  },
})

// Actions
export const { setPrices } = pricesSlice.actions

// Thunks
export const fetchPricesData = () => async (dispatch) => {
  const prices = await fetchPrices()

  dispatch(setPrices(prices))
}

export default pricesSlice.reducer
