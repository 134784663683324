import { AddIcon, Button, Flex, Heading, IconButton, MinusIcon, useModal } from '@bami-libs/uikit'
import BigNumber from 'bignumber.js'
import useStake from 'hooks/useStake'
import useUnstake, { useOldUnstake } from 'hooks/useUnstake'
import { FarmWithStakedValue } from 'models'
import React from 'react'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'

interface FarmCardActionsProps {
  stakedBalance?: BigNumber
  tokenBalance?: BigNumber
  tokenName?: string
  pid?: number
  depositFeeBP?: number
  old?: boolean
  farm: FarmWithStakedValue
}

const IconButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 20px;
  }
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
  stakedBalance,
  tokenBalance,
  tokenName,
  pid,
  depositFeeBP,
  old = false,
  farm,
}) => {
  const { onStake } = useStake(pid, farm.masterChefType)
  const { onUnstake } = useUnstake(pid, farm.masterChefType)
  const { onUnstake: onOldUnStake } = useOldUnstake(pid)

  const handleStake = async (val: any) => {
    await onStake(val)
  }

  const rawStakedBalance = getBalanceNumber(stakedBalance)
  const displayBalance = rawStakedBalance.toLocaleString('en', { maximumFractionDigits: 6 })

  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} onConfirm={handleStake} tokenName={tokenName} depositFeeBP={depositFeeBP} />,
  )
  const [onPresentWithdraw] = useModal(
    <WithdrawModal max={stakedBalance} onConfirm={old ? onOldUnStake : onUnstake} tokenName={tokenName} />,
  )

  const renderStakingButtons = () => {
    // eslint-disable-next-line no-nested-ternary
    return rawStakedBalance === 0 ? (
      old ? (
        <Button style={{ width: '100%' }} disabled size="sm">
          Stake
        </Button>
      ) : (
        <Button style={{ width: '100%' }} onClick={onPresentDeposit} size="sm">
          Stake
        </Button>
      )
    ) : (
      <IconButtonWrapper>
        <IconButton variant="tertiary" onClick={onPresentWithdraw} mr="6px">
          <MinusIcon color="primary" />
        </IconButton>
        <Heading color={rawStakedBalance === 0 ? 'textDisabled' : 'text'}>{displayBalance}</Heading>
        {old ? (
          <IconButton variant="tertiary" style={{ cursor: 'not-allowed' }}>
            <AddIcon color="#ccc" />
          </IconButton>
        ) : (
          <IconButton variant="tertiary" onClick={onPresentDeposit}>
            <AddIcon color="primary" />
          </IconButton>
        )}
      </IconButtonWrapper>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {renderStakingButtons()}
    </Flex>
  )
}

export default StakeAction
