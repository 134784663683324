import BigNumber from 'bignumber.js'
import useRefresh from 'hooks/useRefresh'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { Farm, Pool, State } from './types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    // dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useOldFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.oldFarms.data)
  return farms
}

export const useFarmFromPid = (pid, masterChefType): Farm => {
  const farm = useSelector(
    (state: State) =>
      state.farms.data.find((f) => f.pid === pid && masterChefType === f.masterChefType) ||
      state.oldFarms.data.find((f) => f.pid === pid && masterChefType === f.masterChefType),
  )
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid, masterChefType) => {
  const farm = useFarmFromPid(pid, masterChefType)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

export const useFarmUserTotalStakedValue = () => {
  const farms = useFarms()
  const prices = usePrices()

  return farms.reduce((res, cur) => {
    if (!cur.userData) {
      return res
    }
    const priceToken = cur.isTokenOnly ? cur.lpSymbol : cur.quoteTokenSymbol
    const lpTokenBalance = new BigNumber(cur.userData.stakedBalance)
    let totalValue = new BigNumber(cur.lpTotalInQuoteToken || 0)
    totalValue = totalValue.times(new BigNumber(prices[priceToken]))
    return res.plus(totalValue.times(lpTokenBalance).div(cur.lpTokenBalance))
  }, new BigNumber(0))
}

export const useFarmUserStakedValue = (pid, masterChefType) => {
  const farm = useFarmFromPid(pid, masterChefType)
  const prices = usePrices()

  if (!farm.userData) {
    return new BigNumber(0)
  }

  const priceToken = farm.isTokenOnly ? farm.lpSymbol : farm.quoteTokenSymbol
  const lpTokenBalance = new BigNumber(farm.userData.stakedBalance)
  let totalValue = new BigNumber(farm.lpTotalInQuoteToken || 0)
  totalValue = totalValue.times(new BigNumber(prices[priceToken]))
  return totalValue.times(lpTokenBalance).div(farm.lpTokenBalance)
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  return ZERO
}

export const usePrice = (symbol: string): number => {
  return useSelector((state: State) => state.prices.data[symbol.toUpperCase()] || 0)
}

export const usePrices = (): number => {
  return useSelector((state: State) => state.prices.data)
}
