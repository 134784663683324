import { Menu as UikitMenu } from '@bami-libs/uikit'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { usePrice } from 'state/hooks'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const location = useLocation()
  const bamiPrice = usePrice('BAMI')

  if ((document.querySelector('#root>div:first-child>div:last-child') as HTMLDivElement) && isDark) {
    ;(document.querySelector('#root>div:first-child>div:last-child') as HTMLDivElement).style.backgroundColor =
      '#141E30'
  }

  useEffect(() => {
    if (location.pathname === '/farms') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(6)',
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(6)',
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(6)',
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(6)',
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
    if (location.pathname === '/nests') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(7)',
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(7)',
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(7)',
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(7)',
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
    if (location.pathname === '/farms-old') {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(8)',
      ) as HTMLDivElement).style.background = 'rgba(255, 140, 66,.1)'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(8)',
      ) as HTMLDivElement).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(8)',
      ) as HTMLDivElement).style.background = 'none'
      ;(document.querySelector(
        '#root>div nav + div>div:first-child>div:first-child>div:nth-child(8)',
      ) as HTMLDivElement).style.boxShadow = 'none'
    }
  }, [location.pathname])

  useEffect(() => {
    if (bamiPrice) {
      const price = parseFloat(bamiPrice?.toString() || '0').toFixed(4)
      document.title = `Bami - $${price}`
    }
  }, [bamiPrice])

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={parseFloat(bamiPrice.toString())}
      links={config}
      {...props}
    />
  )
}

export default Menu
