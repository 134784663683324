import { useWalletModal } from '@bami-libs/uikit'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import useI18n from 'hooks/useI18n'
import React from 'react'
import GradientButton from './Button/button'

const UnlockButton = (props) => {
  const TranslateString = useI18n()
  const { connect, reset } = useWallet()
  const { onPresentConnectModal } = useWalletModal(connect, reset)

  return (
    <GradientButton
      width="100%"
      marginRight="0"
      name={TranslateString(292, 'Unlock Wallet')}
      onClick={onPresentConnectModal}
      {...props}
    />
  )
}

export default UnlockButton
