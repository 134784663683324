import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'BAMI-USDT LP',
    lpAddresses: {
      56: '0xb33602ac4bf84b144894cce8f6c39f6c2136340f',
    },
    tokenSymbol: 'BAMI',
    tokenAddresses: contracts.bami,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    rewardTokenSymbol: 'BAMI',
    lockerAddresses: {
      97: '',
      56: '0x784D5E93f1E64692fE60115DB92b678496aD5651',
    },
    newLockerAddresses: {
      97: '',
      56: '0x88746980d403f323e3d5b53691d51970920d1F6a',
    },
  },
  {
    pid: 9,
    risk: 5,
    lpSymbol: 'VNDC-USDT LP',
    lpAddresses: {
      56: '0x40362F99F2Ac08CCB399d9d53dcdF93A30680aE2',
    },
    tokenSymbol: 'VNDC',
    tokenAddresses: {
      56: '0xDDE5B33a56f3F1C22e5a6bd8429E6ad508BFF24E',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    rewardTokenSymbol: 'BAMI',
    lockerAddresses: {
      97: '',
      56: '0x784D5E93f1E64692fE60115DB92b678496aD5651',
    },
    newLockerAddresses: {
      97: '',
      56: '0x88746980d403f323e3d5b53691d51970920d1F6a',
    },
  },
  {
    pid: 10,
    risk: 5,
    lpSymbol: 'VIDB-VNDC LP',
    lpAddresses: {
      56: '0xBae3B5d1072De0DA5940fe42A3474a0013E75C0e',
    },
    tokenSymbol: 'VIDB',
    tokenAddresses: {
      56: '0x90dc17d47d739ee84d61bd0ec828a24881e2a0b4',
    },
    quoteTokenSymbol: QuoteToken.VNDC,
    quoteTokenAdresses: contracts.vndc,
    lockerAddresses: {
      97: '',
      56: '0x784D5E93f1E64692fE60115DB92b678496aD5651',
    },
    rewardTokenSymbol: 'BAMI',
    newLockerAddresses: {
      97: '',
      56: '0x88746980d403f323e3d5b53691d51970920d1F6a',
    },
  },
  {
    pid: 11,
    risk: 5,
    lpSymbol: 'BNB-ETH LP',
    lpAddresses: {
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.eth,
    lockerAddresses: {
      97: '',
      56: '0x784D5E93f1E64692fE60115DB92b678496aD5651',
    },
    rewardTokenSymbol: 'BAMI',
    newLockerAddresses: {
      97: '',
      56: '0x88746980d403f323e3d5b53691d51970920d1F6a',
    },
  },
  {
    pid: 12,
    risk: 5,
    lpSymbol: 'BNB-BTC LP',
    lpAddresses: {
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BTC,
    quoteTokenAdresses: contracts.btc,
    lockerAddresses: {
      97: '',
      56: '0x784D5E93f1E64692fE60115DB92b678496aD5651',
    },
    rewardTokenSymbol: 'BAMI',
    newLockerAddresses: {
      97: '',
      56: '0x88746980d403f323e3d5b53691d51970920d1F6a',
    },
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    lockerAddresses: {
      97: '',
      56: '0x784D5E93f1E64692fE60115DB92b678496aD5651',
    },
    rewardTokenSymbol: 'BAMI',
    newLockerAddresses: {
      97: '',
      56: '0x88746980d403f323e3d5b53691d51970920d1F6a',
    },
  },
  // {
  //   pid: 6,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'VIDB',
  //   disabledDeposit: true,
  //   lpAddresses: {
  //     97: '',
  //     56: '0x90Dc17d47d739Ee84d61Bd0Ec828a24881e2A0B4', // BSCX-BUSD LP
  //   },
  //   tokenSymbol: 'VIDB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x90Dc17d47d739Ee84d61Bd0Ec828a24881e2A0B4',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDT,
  //   quoteTokenAdresses: contracts.usdt,
  //   lockerAddresses: {
  //     97: '',
  //     56: '0x7169Db44eA2a83FE0459504c7F58DF0CF383a31e',
  //   },
  // },
  {
    pid: 7,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BAMI',
    lpAddresses: contracts.bami,
    tokenSymbol: 'BAMI',
    tokenAddresses: contracts.bami,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    rewardTokenSymbol: 'BAMI',
    lockerAddresses: {
      97: '',
      56: '0x7169Db44eA2a83FE0459504c7F58DF0CF383a31e',
    },
    newLockerAddresses: {
      97: '',
      56: '0x88746980d403f323e3d5b53691d51970920d1F6a',
    },
  },
]

export default farms
