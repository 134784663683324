// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@bami-libs/uikit'
import { createGlobalStyle } from 'styled-components'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

    .empty-menu-link {
      flex: 1;
      opacity: 0;
      cursor: default;
      & > a {
        pointer-events: none;
      }
    }

    .dipo-img img {
      height: 24px;
    }

    #root{
      &>div:first-child{
        &>div:last-child{
          background: ${({ theme }) => (theme.isDark ? '#141E30' : '#FFFFFF')}!important;
        }
      }
    }
  }
`

export default GlobalStyle
