import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/ChefBami.json'
import linearReleaseABI from 'config/abi/LinearRelease.json'
import multicall from 'utils/multicall'
import farmsConfig from 'config/constants/farms'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { Farm } from 'state/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchFarmUserAllowances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const masterChefAdress = getMasterChefAddress(farm.masterChefType)
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAdress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const callsDecimal = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return {
      address: lpContractAddress,
      name: 'decimals',
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const decimals = await multicall(erc20ABI, callsDecimal)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance, index) => {
    return new BigNumber(tokenBalance).div(new BigNumber(10).pow(decimals[index])).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmsUserLockedBalance = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const farmLockerAddress = farm.lockerAddresses[CHAIN_ID]
    return {
      address: farmLockerAddress,
      name: 'lockOf',
      params: [account],
    }
  })

  const rawLockedTokenBalances = await multicall(linearReleaseABI, calls)
  const parsedLockedTokenBalances = rawLockedTokenBalances.map((lockedTokenBalance) => {
    return new BigNumber(lockedTokenBalance).toJSON()
  })
  return parsedLockedTokenBalances
}

export const fetchFarmUserLockedBalance = async (account: string, farm: Farm) => {
  const hasLocker = farm.newLockerAddresses?.[CHAIN_ID] || farm.lockerAddresses?.[CHAIN_ID]
  if (!hasLocker) {
    return null
  }
  const call = farm.newLockerAddresses
    ? [
        {
          address: farm.newLockerAddresses[CHAIN_ID],
          name: 'lockOf',
          params: [account],
        },
        {
          address: farm.lockerAddresses[CHAIN_ID],
          name: 'lockOf',
          params: [account],
        },
      ]
    : [
        {
          address: farm.lockerAddresses[CHAIN_ID],
          name: 'lockOf',
          params: [account],
        },
      ]

  const rawLockedTokenBalances = await multicall(linearReleaseABI, call)
  const parsedLockedTokenBalances = rawLockedTokenBalances.reduce((res, lockedTokenBalance) => {
    return res.plus(new BigNumber(lockedTokenBalance))
  }, new BigNumber(0))

  return parsedLockedTokenBalances.toJSON()
}

export const fetchFarmUserLockedBalanceSeparated = async (account: string, farm: Farm) => {
  const call = farm.newLockerAddresses
    ? [
        {
          address: farm.lockerAddresses[CHAIN_ID],
          name: 'lockOf',
          params: [account],
        },
        {
          address: farm.newLockerAddresses[CHAIN_ID],
          name: 'lockOf',
          params: [account],
        },
      ]
    : [
        {
          address: farm.lockerAddresses[CHAIN_ID],
          name: 'lockOf',
          params: [account],
        },
      ]

  const rawLockedTokenBalances = await multicall(linearReleaseABI, call)
  const parsedLockedTokenBalances = rawLockedTokenBalances.map((lockedTokenBalance) => {
    return new BigNumber(lockedTokenBalance)
  })

  return parsedLockedTokenBalances.length === 2
    ? {
        oldLocked: parsedLockedTokenBalances[0],
        newLocked: parsedLockedTokenBalances[1],
      }
    : {
        oldLocked: parsedLockedTokenBalances[0],
      }
}

export const fetchFarmUserPendingLockedBalanceSeparated = async (account: string, farm: Farm) => {
  const call = farm.newLockerAddresses
    ? [
        {
          address: farm.lockerAddresses[CHAIN_ID],
          name: 'pendingTokens',
          params: [account],
        },
        {
          address: farm.newLockerAddresses[CHAIN_ID],
          name: 'pendingTokens',
          params: [account],
        },
      ]
    : [
        {
          address: farm.lockerAddresses[CHAIN_ID],
          name: 'pendingTokens',
          params: [account],
        },
      ]

  try {
    const rawLockedTokenBalances = await multicall(linearReleaseABI, call)
    const parsedLockedTokenBalances = rawLockedTokenBalances.map((lockedTokenBalance) => {
      return new BigNumber(lockedTokenBalance[1])
    })

    return parsedLockedTokenBalances.length === 2
      ? {
          oldPendingLocked: parsedLockedTokenBalances[0],
          newPendingLocked: parsedLockedTokenBalances[1],
        }
      : {
          oldPendingLocked: parsedLockedTokenBalances[0],
        }
  } catch (err) {
    console.log('err', err)
  }

  return {}
}

export const fetchFarmUserStakedBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const masterChefAdress = getMasterChefAddress(farm.masterChefType)
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const masterChefAdress = getMasterChefAddress(farm.masterChefType)
    const name = farm.masterChefType === 'ltd' ? 'pendingLTDToken' : 'pendingBami'
    return {
      address: masterChefAdress,
      name,
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}
